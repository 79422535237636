import React from 'react';

const InnerLogo = (props : any) => {
  return (
    <img
      alt="Logo"
      src="/static/main_login_logo.png"
      {...props}
    />
  );
}

export default InnerLogo;
