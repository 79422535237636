import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from '../lib/firebase';
import "firebase/firestore";
import { ProductionRequestDto } from 'src/types/productionDto';
import { UdiInfoDto } from 'src/types/udiDto';

interface ProductionStatus {
  productionRequestList: ProductionRequestDto[],
  productionRequest: ProductionRequestDto,
  productionRequestUdiList: UdiInfoDto[],
}

export const initialState: ProductionStatus = {
    productionRequestList: [],
    productionRequest: {
      id: "",
      manufacturingReferral: "",
      productionOrder: "",
      manufacturingRecords: "",
      requestsDepartment: "",
      reagent: {
          name: "",
          di: "",
          catNo: "",
          productCategory: "",
          swNeedYn: "",
      },
      sw: {
        name: "",
        di: "",
        catNo: "",
        productCategory : "",
        version: "",
      },
      manufacturingDate: new Date(),
      lotNo: "",
      reaction: 0,
      quantity: 0,
      permission: "",
      productionConfirmStatus: "",
      productEffectivePeriod: 0,
      productEffectiveDate: new Date(),
      analysisEffectivePeriod: 0,
      licenseIssueStatus: "",
      examRequestDate: "",
      examMethod: "",
      examRequest: "",
      acReaction: "",
      qcQuantity: "",
      examRequestPeriod: "",
      passYn: "",
      examCompleteDate: "",
      examReportNumber: "",
      coaNumber: "",
      experimenter: "",
      assayer: "",
      qcConfirmStatus: "",
      transferStatus: "",
      panelInformation: "",
      note: "",
      register: "",
      registDate: new Date(),
      modify: [
        {
          modifier: "",
          modifyDate: new Date(),
          reason: ""
        }
      ]
      
    },
    productionRequestUdiList: [],
};

const slice = createSlice({
  name: 'productionRequest',
  initialState,
  reducers: {
    getRequestList(state, action: PayloadAction<ProductionRequestDto[]>) {
      const productionRequestList = action.payload;
      state.productionRequestList = productionRequestList;
    },
    getRequest(state, action: PayloadAction<ProductionRequestDto>) {
      const productionRequest = action.payload;
      state.productionRequest = productionRequest;
    },
    getRequestUdi(state, action: PayloadAction<UdiInfoDto[]>) {
      const productionRequestUdiList = action.payload;
      state.productionRequestUdiList = productionRequestUdiList;
    },
  }
});

export const reducer = slice.reducer;

export const getRequestList = () => async (dispatch : any) => {
  const productionRequestData: any = [];
  const productionRequest = firebase.firestore().collection('productionRequest').orderBy('requestInfo.registDate', 'desc');

  productionRequest.get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        productionRequestData.push({ 
          id: doc.id,
          manufacturingReferral: doc.data().requestInfo.manufacturingReferral,
          productionOrder: doc.data().requestInfo.productionOrder,
          manufacturingRecords: doc.data().requestInfo.manufacturingRecords,
          requestsDepartment: doc.data().requestInfo.requestsDepartment,
          reagent: {
            name: doc.data().requestInfo.reagent.name,
            di: doc.data().requestInfo.reagent.di,
            catNo: doc.data().requestInfo.reagent.catNo,
            productCategory: doc.data().requestInfo.reagent.productCategory,
            swNeedYn: doc.data().requestInfo.reagent.swNeedYn,
          },
          sw: {
            name: doc.data().requestInfo.sw.name,
            di: doc.data().requestInfo.sw.di,
            catNo: doc.data().requestInfo.sw.catNo,
            productCategory: doc.data().requestInfo.sw.productCategory,
            version: doc.data().requestInfo.sw.version,
          },
          manufacturingDate: doc.data().requestInfo.manufacturingDate,
          lotNo: doc.data().requestInfo.lotNo,
          reaction: doc.data().requestInfo.reaction,
          quantity: doc.data().requestInfo.quantity,
          permission: doc.data().requestInfo.permission,
          productionConfirmStatus: doc.data().requestInfo.productionConfirmStatus,
          productEffectivePeriod: doc.data().requestInfo.productEffectivePeriod,
          productEffectiveDate: doc.data().requestInfo.productEffectiveDate,
          analysisEffectivePeriod: doc.data().requestInfo.analysisEffectivePeriod,
          licenseIssueStatus: doc.data().requestInfo.licenseIssueStatus,
          examRequestDate: doc.data().requestInfo.examRequestDate,
          examMethod: doc.data().requestInfo.examMethod,
          examRequest: doc.data().requestInfo.examRequest,
          acReaction: doc.data().requestInfo.acReaction,
          qcQuantity: doc.data().requestInfo.qcQuantity,
          examRequestPeriod: doc.data().requestInfo.examRequestPeriod,
          passYn: doc.data().requestInfo.passYn,
          examCompleteDate: doc.data().requestInfo.examCompleteDate,
          examReportNumber: doc.data().requestInfo.examReportNumber,
          coaNumber: doc.data().requestInfo.coaNumber,
          experimenter: doc.data().requestInfo.experimenter,
          assayer: doc.data().requestInfo.assayer,
          qcConfirmStatus: doc.data().requestInfo.qcConfirmStatus,
          register: doc.data().requestInfo.register,
          registDate: doc.data().requestInfo.registDate,
          transferStatus: doc.data().requestInfo.transferStatus,
          panelInformation: doc.data().requestInfo.panelInformation,
          note: doc.data().requestInfo.note,
          modify: doc.data().requestInfo.modify,
        });
      });
    dispatch(slice.actions.getRequestList(productionRequestData));
  })
  .catch(err => {
    alert(`${err}: 목록을 불러오는데 실패했습니다. 관리자에게 문의하세요`);
  });
};

export const setProductionRequest = (requestInfo: any) => async (dispatch : any) => {
  await firebase.firestore().collection('productionRequest').add(requestInfo).then((doc) => {
    console.log(requestInfo)
  }).catch((error) => {
    console.log("Error getting document:", error);
    alert(`${error}: 생산정보등록에 실패하였습니다. 관리자에게 문의하세요`);
  });
};

export const productionRequestConfirm = (productionDocumentId: string, email: string) => async (dispatch: any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({    
    "requestInfo.productionConfirmStatus" :  "Y",
    "requestInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(
      {
        modifier : email,
        modifyDate : new Date(),
        reason : "생산확정"
      }
    )
  }).then((docRef) => {
    window.location.replace("/app/management/production");
  })
  .catch((error) => {
    alert(`${error}: 생산확정에 실패했습니다. 관리자에게 문의하세요`);
  });
}

export const productionTransferConfirm = (productionDocumentId: string, email: string) => async (dispatch: any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({
    "requestInfo.transferStatus" :  "Y",
    "requestInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(
        {
          modifier : email,
          modifyDate : new Date(),
          reason : "이관확정"
        }
    )
  }).then((docRef) => {
    window.location.replace("/app/management/production");
  })
      .catch((error) => {
        alert(`${error}: 이관에 실패했습니다. 관리자에게 문의하세요`);
      });
}


export const createUdi = (udiInfoList: any, productionDocumentId: string, email: string) => async (dispatch : any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({    
    "requestInfo.licenseIssueStatus" :  "Y",
    "requestInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(
      {
        modifier : email,
        modifyDate : new Date(),
        reason : "라이선스 발급"
      }
    )
  }).then((doc) => {
    udiInfoList.forEach((udiInfo: UdiInfoDto) => {
      firebase.firestore().collection('productUdi').add({udiInfo} );
    });
    alert('UDI 발급이 완료되었습니다.');
    window.setTimeout(slowAlert,3000);

    function slowAlert() {
      window.location.replace("/app/management/production");
    }

  }).then(() => {

  }).catch((error) => {
    console.log("Error getting document:", error);
    alert(`${error}: 라이선스 미발급에 실패하였습니다. 관리자에게 문의하세요`)
  });
};

export const notCreateUdi = (productionDocumentId: string, email: string) => async (dispatch: any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({    
    "requestInfo.licenseIssueStatus": "N",
    "requestInfo.modify": firebase.firestore.FieldValue.arrayUnion(
      {
        modifier : email,
        modifyDate : new Date(),
        reason : "라이선스 미발급"
      }
    )
  }).then((doc) => {
    window.location.replace("/app/management/production");
  }).catch((error) => {
    console.log("Error getting document:", error);
    alert(`${error}: 라이선스 미발급에 실패하였습니다. 관리자에게 문의하세요`)
  });
};

export const getRequest = (productionRequestId: string) => async (dispatch: any) => {
  let requestDetail: any = firebase.firestore().collection('productionRequest').doc(productionRequestId);
  requestDetail.get().then( async(querySnapshot) => {
    const productionRequest: any = {
      "requestDocumentId": productionRequestId,
      "requestInfo": querySnapshot.data().requestInfo
    };
    await dispatch(slice.actions.getRequest(productionRequest));
  })
  .catch((err:any) => {
    alert(`${err}: 정보를 불러오는데 실패했습니다. 관리자에게 문의하세요`);
  });
}

export const getRequestUdi = (productionRequestId: string) => async (dispatch: any) => {
  const productionRequestUdiList: any = [];
  const productionRequestUdi = firebase.firestore().collection('productUdi').where("udiInfo.productionRequestId", "==", productionRequestId);
  productionRequestUdi.get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        productionRequestUdiList.push({ id: doc.id, data: doc.data() });
      });
      dispatch(slice.actions.getRequestUdi(productionRequestUdiList));
  })
  .catch(err => {
      alert(`${err}: 정보를 불러오는데 실패했습니다. 관리자에게 문의하세요`);
  });
}

export const modifyProductionRequest = (productionDocumentId: string, requestInfo: any) => async (dispatch: any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({    
    "requestInfo.manufacturingReferral": requestInfo.manufacturingReferral,
    "requestInfo.productionOrder": requestInfo.productionOrder,
    "requestInfo.manufacturingRecords": requestInfo.manufacturingRecords,
    "requestInfo.requestsDepartment": requestInfo.requestsDepartment,
    "requestInfo.reagent.name": requestInfo.reagent.name,
    "requestInfo.reagent.di": requestInfo.reagent.di,
    "requestInfo.reagent.catNo": requestInfo.reagent.catNo,
    "requestInfo.reagent.productCategory": requestInfo.reagent.productCategory,
    "requestInfo.reagent.swNeedYn": requestInfo.reagent.swNeedYn,
    "requestInfo.sw.name": requestInfo.sw.name,
    "requestInfo.sw.di": requestInfo.sw.di,
    "requestInfo.sw.catNo": requestInfo.sw.catNo,
    "requestInfo.sw.productCategory": requestInfo.sw.productCategory,
    "requestInfo.sw.version": requestInfo.sw.version,
    "requestInfo.manufacturingDate": requestInfo.manufacturingDate,
    "requestInfo.lotNo": requestInfo.lotNo,
    "requestInfo.reaction": requestInfo.reaction,
    "requestInfo.quantity": requestInfo.quantity,
    "requestInfo.permission": requestInfo.permission,
    "requestInfo.productEffectivePeriod": requestInfo.productEffectivePeriod,
    "requestInfo.productEffectiveDate": requestInfo.productEffectiveDate,
    "requestInfo.analysisEffectivePeriod": requestInfo.analysisEffectivePeriod,
    "requestInfo.modify": firebase.firestore.FieldValue.arrayUnion(requestInfo.modify[0])
  }).then((doc) => {
    window.location.replace('/app/management/production');
  }).catch((error) => {
    console.log("Error getting document:", error);
    alert(`${error}: 생산정보 수정에 실패하였습니다. 관리자에게 문의하세요`)
  });
};

export const inputQcInfo = (productionDocumentId: string, requestInfo: any) => async (dispatch: any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({    
    "requestInfo.examRequestDate": requestInfo.examRequestDate,
    "requestInfo.examMethod": requestInfo.examMethod,
    "requestInfo.examRequest": requestInfo.examRequest,
    "requestInfo.acReaction": requestInfo.acReaction,
    "requestInfo.qcQuantity": requestInfo.qcQuantity,
    "requestInfo.examRequestPeriod": requestInfo.examRequestPeriod,
    "requestInfo.passYn": requestInfo.passYn,
    "requestInfo.examCompleteDate": requestInfo.examCompleteDate,
    "requestInfo.examReportNumber": requestInfo.examReportNumber,
    "requestInfo.coaNumber": requestInfo.coaNumber,
    "requestInfo.qcConfirmStatus": requestInfo.qcConfirmStatus,
    "requestInfo.assayer": requestInfo.assayer,
    "requestInfo.experimenter": requestInfo.experimenter ,
    "requestInfo.modify": firebase.firestore.FieldValue.arrayUnion(requestInfo.modify[0])
  }).then((doc) => {
  }).catch((error) => {
    console.log("Error getting document:", error);
    alert(`${error}: QC정보 등록에 실패하였습니다. 관리자에게 문의하세요`)
  });
};

export const qcConfirm = (productionDocumentId: string, email: string) => async (dispatch: any) => {
  firebase.firestore().collection('productionRequest').doc(productionDocumentId).update({    
    "requestInfo.qcConfirmStatus" :  "Y",
    "requestInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(
      {
        modifier: email,
        modifyDate: new Date(),
        reason: "QC 확정"
      }
    )
  }).then((docRef) => {
    window.location.replace("/app/management/production");

  })
  .catch((error) => {
    alert(`${error}: 생산확정에 실패했습니다. 관리자에게 문의하세요`);
  });
}

export default slice;
