import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from '../lib/firebase';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import { ApiUserDto } from 'src/types/apiUserDto';

interface ApiStatus {
  apiUser: ApiUserDto[],
  apiUserDetail: ApiUserDto
}

const initialState: ApiStatus = {
  apiUser: [],
  apiUserDetail: {
    id: '',
    activate: 'Y',
    modify: [],
    password: '',
    registDate: {
      seconds: 0,
      nanoseconds: 0,
    },
    register: '',
    systemDescription: '',
    systemName: '',
    validationDate: ''
  }
}

const slice = createSlice({
  name: 'apiSlice',
  initialState,
  reducers: {
    getApi(state, action: PayloadAction<ApiUserDto[]>) {
      const apiUser = action.payload;
      state.apiUser = apiUser
    },
    resetApiDetail(state, action: PayloadAction<ApiUserDto>){
      const apiUserDetail = action.payload;
      state.apiUserDetail = apiUserDetail
    },
  }
})

export const reducer = slice.reducer;

export const setApi = (apiInfo: any) => async (dispatch: any) => {
  await firebase.firestore().collection('apiSystem').add({ apiInfo })
  .then((docRef) => {
    const hmacDigest = Base64.stringify(hmacSHA256(apiInfo.password, docRef.id));
    firebase.firestore().collection('apiSystem').doc(docRef.id).update({"apiInfo.password": hmacDigest})
  })
  .catch((error) => {
      console.error("Error adding document: ", error);
  });
}

export const getApi = (type: string) => async (dispatch: any) => {
  let apiList: any = [];
  type === 'apis' 
  ? apiList = firebase.firestore().collection('apiSystem').orderBy('apiInfo.registDate', 'desc')
  : apiList = firebase.firestore().collection('apiSystem').orderBy('apiInfo.registDate', 'desc').limit(1)

  apiList.get().then((querySnapshot) => {
    const apiArr: any = [];
    querySnapshot.forEach((doc: any) => {
      apiArr.push({id: doc.id, data: doc.data()})
    });
    dispatch(slice.actions.getApi(apiArr));
  })
  .catch((err:any) => {
    alert(`${err}: 목록을 불러오는데 실패했습니다. 관리자에게 문의하세요`);
  });
}

export const resetApiDetail = () => async (dispatch: any) => {
  const apiDetail: ApiUserDto = {
    id: '',
    activate: 'Y',
    modify: [],
    password: '',
    registDate: {
      seconds: 0,
      nanoseconds: 0,
    },
    register: '',
    systemDescription: '',
    systemName: '',
    validationDate: ''
  };
  dispatch(slice.actions.resetApiDetail(apiDetail));
}

export const reIssueApiKey = (apiDocumentId:string, apiInfo: any) => async (dispatch: any) => {
  const hmacDigest = Base64.stringify(hmacSHA256(apiInfo.password, apiDocumentId));
  firebase.firestore().collection('apiSystem').doc(apiDocumentId).update({    
    "apiInfo.password" :  hmacDigest,
    "apiInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(apiInfo.modify),
  }).then((docRef) => {})
  .catch((error) => {
    alert(`${error}: 재발급에 실패했습니다. 관리자에게 문의하세요`);
  });
}

export const inactivateSystem = (apiDocumentId:string, apiInfo: any) => async (dispatch: any) => {
  firebase.firestore().collection('apiSystem').doc(apiDocumentId).update({    
    "apiInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(apiInfo.modify),
    "apiInfo.activate" : 'N',
    "apiInfo.inactivateUser" : apiInfo.inactivateUser,
    "apiInfo.inactivateDate" : new Date(),

  }).then((docRef) => {
    window.location.replace('/app/management/api');
  })
  .catch((error) => {
    alert(`${error}: 시스템 비활성화에 실패하였습니다. 관리자에게 문의하세요`);
  });
}

export const modifySystemData = (apiDocumentId:string, apiInfo: any) => async (dispatch: any) => {
  firebase.firestore().collection('apiSystem').doc(apiDocumentId).update({
    "apiInfo.modify" :  firebase.firestore.FieldValue.arrayUnion(apiInfo.modify),
    "apiInfo.systemName" : apiInfo.systemName,
    "apiInfo.systemDescription" : apiInfo.systemDescription,
  }).then((docRef) => {
    window.location.replace('/app/management/api');
  })
  .catch((error) => {
    alert(`${error}: 시스템 수정에 실패하였습니다. 관리자에게 문의하세요`);
  });
}

export default slice;