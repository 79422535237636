import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import "firebase/firestore";


const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state :any, action:any) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  // createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: (email:string, password:string) => Promise.resolve(),
  // signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }:any) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email:any, password:any) => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(function() {
      return firebase.auth().signInWithEmailAndPassword(email, password);
    })
    .catch(function(error) {
      alert('아이디 또는 비밀번호가 잘못되었습니다.')
      console.log("error",error.code, error.message);
    });
  };

  // const signInWithGoogle = () => {
  //   const provider = new firebase.auth.GoogleAuthProvider();

  //   return firebase.auth().signInWithPopup(provider);
  // };

  // const createUserWithEmailAndPassword = async (email:string, password:string) => {
  //   return firebase.auth().createUserWithEmailAndPassword(email, password);
  // };

  const logout = () => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    try{
      const unsubscribe = firebase.auth().onAuthStateChanged((user:any) => {


        if (user) {
          const uid = user.uid;
          const userRef = firebase.firestore().collection('users').doc(uid);
          userRef.get().then(doc => {
            if (doc.exists) {
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: true,
                  user: {
                    id: uid,
                    username: doc.get('username'),
                    email: doc.get('email'),
                    firstName: doc.get('firstName'),
                    lastName: doc.get('lastName'),
                    bio: doc.get('bio'),
                    role: doc.get("role").role, 
                    authority : doc.get("role").authority
                  }
                }
              });
            } else {
            //  reject('User detail not found.');
            }
          });
          
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      });
  
      return unsubscribe;

    } catch (err) {
        console.error(err);
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        // createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        // signInWithGoogle,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
