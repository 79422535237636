import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import firebase from '../lib/firebase';
import {ProductsDto} from 'src/types/productsDto'



interface ProductStatus {
    products: ProductsDto[],
    productsCompareList: ProductsDto[],
}

const initialState: ProductStatus = {
    products: [],
    productsCompareList: []
}


const slice = createSlice({
    name: 'productReducer',
    initialState,
    reducers: {
        getProducts(state, action: PayloadAction<ProductsDto[]>) {
            const products = action.payload;
            state.products = products;
        },
        getProductsComapreList :{
            reducer:(state, action: PayloadAction<ProductsDto[]>) => {
                state.productsCompareList = action.payload;

            },
            prepare:(firebaseData: any) => {
                return {
                    payload: firebaseData.map((doc) => ({
                            id: doc.id,
                            productType: doc.data().productType,
                            name: doc.data().name,
                            di: doc.data().di,
                            catNo: doc.data().catNo,
                            productEffectivePeriod: doc.data().productEffectivePeriod,
                            analysisEffectivePeriod: doc.data().analysisEffectivePeriod,
                            reaction: doc.data().reaction,
                            activateYn: doc.data().activateYn,
                            register: doc.data().register,
                            registDate: doc.data().registDate,
                            productCategory: doc.data().productCategory,
                            version: doc.data().version,
                            swNeedYn: doc.data().swNeedYn,
                            productCode: doc.data().productCode,
                        })
                    )
                }
            }
        },
    }
})

export const reducer = slice.reducer;
export const getProducts = () => async (dispatch: any) => {
    const productsData: ProductsDto[] = [];
    const productsRef = firebase.firestore().collection('productsInfo').orderBy('catNo');
    productsRef
        .get()
        .then(snapshot => {

            const productsData: ProductsDto[] = snapshot.docs.filter(doc => doc.data().activateYn === 'Y').map(doc =>
                ({
                    id: doc.id,
                    productType: doc.data().productType,
                    name: doc.data().name,
                    di: doc.data().di,
                    catNo: doc.data().catNo,
                    productEffectivePeriod: doc.data().productEffectivePeriod,
                    analysisEffectivePeriod: doc.data().analysisEffectivePeriod,
                    reaction: doc.data().reaction,
                    activateYn: doc.data().activateYn,
                    register: doc.data().register,
                    registDate: doc.data().registDate,
                    productCategory: doc.data().productCategory,
                    version: doc.data().version,
                    swNeedYn: doc.data().swNeedYn,
                    productCode: doc.data().productCode,
                })
            );
            dispatch(slice.actions.getProducts(productsData));
        })
        .catch(err => {
            alert(`${err}: 목록을 불러오는데 실패했습니다. 관리자에게 문의하세요`);
        });
};

export const getProductsComapreList = () => async (dispatch: any) => {
    const productsData: ProductsDto[] = [];
    const productsRef = firebase.firestore().collection('productsInfo').orderBy('catNo');


    productsRef
        .get()
        .then(snapshot => {
            dispatch(slice.actions.getProductsComapreList(snapshot.docs));
        })
        .catch(err => {
            alert(`${err}: 목록을 불러오는데 실패했습니다. 관리자에게 문의하세요`);
        });
};

// data 밀어넣기 용도
export const setProductsDiRef = (data: ProductsDto, email: string) => async (dispatch: any) => {

    let productsData: ProductsDto;

    const productsRef = firebase.firestore().collection('productsInfo');
    productsRef.doc(data.di).get().then(
        snapshot => {
            if (snapshot.data() === undefined) {
                productsRef.doc(data.di).set(data);
                console.log("완료");
            } else {
                if (!(data.productType === snapshot.data().productType &&
                    data.name === snapshot.data().name &&
                    data.catNo === snapshot.data().catNo &&
                    data.productEffectivePeriod === snapshot.data().productEffectivePeriod &&
                    data.analysisEffectivePeriod === snapshot.data().analysisEffectivePeriod &&
                    data.reaction === snapshot.data().reaction &&
                    data.activateYn === snapshot.data().activateYn &&
                    data.productCategory === snapshot.data().productCategory &&
                    data.version === snapshot.data().version &&
                    data.swNeedYn === snapshot.data().swNeedYn &&
                    data.productCode === snapshot.data().productCode)) {

                    if (data.activateYn !== snapshot.data().activateYn) {
                        productsRef.doc(data.di).update({
                            activateYn: data.activateYn,
                            modify: firebase.firestore.FieldValue.arrayUnion({
                                modifier: email,
                                modifyDate: new Date(),
                                reason: "제품 비활성화"
                            }),
                            inactivateDate: new Date(),
                            inactivateUser: email,
                        })
                    } else {
                        productsRef.doc(data.di).update({
                            productType: data.productType,
                            productCategory: data.productCategory,
                            productCode: data.productCode,
                            name: data.name,
                            catNo: data.catNo,
                            productEffectivePeriod: data.productEffectivePeriod,
                            analysisEffectivePeriod: data.analysisEffectivePeriod,
                            reaction: data.reaction,
                            modify: firebase.firestore.FieldValue.arrayUnion({
                                modifier: email,
                                modifyDate: new Date(),
                                reason: "제품정보수정"
                            }),
                            version: data.version,
                            swNeedYn: data.swNeedYn,
                        })
                    }
                }
            }
        })
        .catch(err => {
            alert(`${err}: 저장에 실패했습니다. 관리자에게 문의하세요`);
        });
}