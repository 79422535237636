import React from 'react';

const Logo = (props : any) => {
  return (
    <img
      alt="Logo"
      src="/static/logo_b.png"
      {...props}
    />
  );
}

export default Logo;
