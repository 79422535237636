export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: "AIzaSyBvF0ZtnVyPlSAehvQ39GKwUkuc3dWni7U",
  authDomain: "product-license-287801.firebaseapp.com",
  databaseURL: "https://product-license-287801.firebaseio.com",
  projectId: "product-license-287801",
  storageBucket: "product-license-287801.appspot.com",
  messagingSenderId: "16612315776",
  appId: "1:16612315776:web:be1a233ef2057345940c65"
};

// export const firebaseConfig = {
//   apiKey: "AIzaSyC3pXQ_-6CaaphOTTHyI8ogdO9kDz3c2sQ",
//   authDomain: "ngas-cloud-dev.firebaseapp.com",
//   databaseURL: "https://ngas-cloud-dev.firebaseio.com",
//   projectId: "ngas-cloud-dev",
//   storageBucket: "ngas-cloud-dev.appspot.com",
//   messagingSenderId: "957860233556",
//   appId: "1:957860233556:web:c1ed91b8a9596ab4c108c1"
// };