import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase from '../lib/firebase';
import { UdiInfoDto } from 'src/types/udiDto';

interface UdiStatus {
  udi: UdiInfoDto[],
}

const initialState: UdiStatus = {
  udi: [],
};

const slice = createSlice({
  name: 'udi',
  initialState,
  reducers: {
    getUdiList(state, action: PayloadAction<UdiInfoDto[]>) {
      const udi = action.payload;
      state.udi = udi;
    },
  }
});

export const reducer = slice.reducer;

export const getUdiList = () => async (dispatch: any) => {
  const udiList = firebase.firestore().collection('productUdi').orderBy('udiInfo.registDate', 'desc');
  udiList
  .get()
  .then(snapshot => {
      const udiArr: UdiInfoDto[] = [];
      snapshot.forEach(doc => {
        udiArr.push({ 
          id: doc.id,
          analysisEffectiveDate: doc.data().udiInfo.analysisEffectiveDate,
          analysisEffectivePeriod: doc.data().udiInfo.analysisEffectivePeriod,
          customerUseStatus: doc.data().udiInfo.customerUseStatus,
          customer: doc.data().udiInfo.customer,
          firstAnalyzedDate: doc.data().udiInfo.firstAnalyzedDate,
          issueDate: doc.data().udiInfo.issueDate,
          modify: doc.data().udiInfo.modify,
          pi: doc.data().udiInfo.pi,
          productEffectiveDate: doc.data().udiInfo.productEffectiveDate,
          productEffectivePeriod: doc.data().udiInfo.productEffectivePeriod,
          productionRequestId: doc.data().udiInfo.productionRequestId,
          reaction: doc.data().udiInfo.reaction,
          reagent: {
            catNo: doc.data().udiInfo.reagent.catNo,
            di: doc.data().udiInfo.reagent.di,
            name: doc.data().udiInfo.reagent.name,
            productCategory: doc.data().udiInfo.reagent.productCategory,
          },
          registDate: doc.data().udiInfo.registDate,
          register: doc.data().udiInfo.register,
          sw: {
            catNo: doc.data().udiInfo.sw.catNo,
            di: doc.data().udiInfo.sw.di,
            name: doc.data().udiInfo.sw.name,
            productCategory: doc.data().udiInfo.sw.productCategory,
            version: doc.data().udiInfo.sw.version,
          },
          type: doc.data().udiInfo.type,
          useNumber: doc.data().udiInfo.useNumber,
        });
      });
      dispatch(slice.actions.getUdiList(udiArr));
  })
  .catch(err => {
      alert(`${err}: 목록을 불러오는데 실패했습니다. 관리자에게 문의하세요`);
  });
};

export const setProduct = (udiInfo: UdiInfoDto) => async (dispatch: any) => {
  await firebase.firestore().collection('productUdi').add({udiInfo} ).then((doc) => {
  }).catch((error) => {
    console.log("Error getting document:", error);
  });
};

export const setUdiConfirm = (udiId: string, userName: string) => async (dispatch: any) => {
  await firebase.firestore().collection('productUdi')
  .doc(udiId)
  .update({
    'udiInfo.confirmYn': 'Y',
    'udiInfo.confirmDate': new Date(),
    'udiInfo.confirmUser': userName,
  })
  .then(function() {
    console.log('Document successfully updated!');
  })
  .catch(function(error) {
    // The document probably doesn't exist.
    console.error('Error updating document: ', error);
  });
};

export default slice;
