import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as udiReducer } from 'src/slices/udiSlice';
import { reducer as productionRequest } from 'src/slices/productionRequestSlice';
import { reducer as apiUserReducer} from 'src/slices/apiUserSlice';
import { reducer as productReducer} from 'src/slices/productSlice';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer, 
  udi: udiReducer,
  productionRequest: productionRequest,
  apiUser: apiUserReducer,
  product: productReducer,
  productsCompareList: productReducer,
});

export default rootReducer;
